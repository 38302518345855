import { OpenPanel } from "@openpanel/web"

const isTrackingDisabled =
  window.safeLocalStorage.getItem("openpanel_ignore") === "true" || false

const op = new OpenPanel({
  apiUrl: "/api/pipe",
  clientId: "",
  trackScreenViews: true,
  disabled: isTrackingDisabled,
})

window.op = op

if (window?.user?.id) {
  op.identify({
    profileId: window.user.id,
    properties: {
      experiment: window.experimentsString,
    },
  })
}

function toCamelCase(str: string) {
  return str.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace("-", "").replace("_", ""),
  )
}

// Send events directly from links
document.addEventListener("click", async (event) => {
  const target = event.target as HTMLElement
  const link = target.closest("a[data-track]") as HTMLAnchorElement | null
  const eventName = link?.getAttribute("data-track")

  if (!link || !link.href || !eventName) return

  // Prevent default navigation
  event.preventDefault()

  const eventData = [...link.attributes].reduce((acc, attr) => {
    if (attr.name.startsWith("data-track-")) {
      acc[toCamelCase(attr.name.replace("data-track-", ""))] = attr.value
    }
    return acc
  }, {})

  try {
    await window.sendEvent(eventName, eventData)
  } catch (error) {
    console.error("Failed to send analytics event:", error)
  }

  // Continue with navigation
  window.location.href = link.href
})
